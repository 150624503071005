import React, { lazy } from 'react';
import {
	dashboardMenu,
	demoPages,
	personalPages,
	memberPages,
	productPages,
	licensePages,
	invitationPages,
	renewalPages,
	salePages
} from '../menu';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const MEMBERS = {
	PERSONAL_PROFILE: lazy(() => import('../pages/members/personal/ProfilePage')),
	AGENT_LIST: lazy(() => import('../pages/members/AgentList')),
	MANAGER_LIST: lazy(() => import('../pages/members/ManagerList')),
};
const PRODUCTS = {
	PRODUCT_LIST: lazy(() => import('../pages/products/ProductList')),
};
const LICENSES = {
	LICENSE_LIST: lazy(() => import('../pages/licenses/LicenseList')),
};
const INVITATIONS = {
	INVITATION_LIST: lazy(() => import('../pages/invitations/InvitationList')),
};
const RENEWALS = {
	RENEWAL_LIST: lazy(() => import('../pages/renewals/RenewalList')),
};
const SALE_RECORDS = {
	SALE_LIST: lazy(() => import('../pages/sales/SaleList')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	{
		path: demoPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},

	/**
	 * Members
	 */
	{
		path: personalPages.personalProfile.path,
		element: <MEMBERS.PERSONAL_PROFILE />,
		exact: true,
	},
	{
		path: memberPages.manager.path,
		element: <MEMBERS.MANAGER_LIST />,
		exact: true,
	},
	{
		path: memberPages.agent.path,
		element: <MEMBERS.AGENT_LIST />,
		exact: true,
	},

	/**
	 * Products
	 */
	{
		path: productPages.product.path,
		element: <PRODUCTS.PRODUCT_LIST />,
		exact: true,
	},

	/**
	 * Licenses
	 */
	{
		path: licensePages.license.path,
		element: <LICENSES.LICENSE_LIST />,
		exact: true,
	},

	/**
	 * Invitations
	 */
	{
		path: invitationPages.invitation.path,
		element: <INVITATIONS.INVITATION_LIST />,
		exact: true,
	},

	/**
	 * Renewals
	 */
	{
		path: renewalPages.renewal.path,
		element: <RENEWALS.RENEWAL_LIST />,
		exact: true,
	},
	
	/**
	 * Sale Records
	 */
	{
		path: salePages.sale.path,
		element: <SALE_RECORDS.SALE_LIST />,
		exact: true,
	},
];
const contents = [...presentation];

export default contents;
